

/* Mobile */

@media screen and (min-width: 992px) {
    .car-right-box{
        position: absolute;
        margin-bottom: 0;
    }
}
@media screen and (min-width: 320px) {
    .oportunidades{
        .botao-default{
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    .box-marcas{
        .marcas-seminovos{
            text-align: left;
            .item-marca-seminovos{
                margin-bottom: 20px;
            }
        }

        .marcas{
            .item-marcas{
                margin-bottom: 20px;
            }
        }
    }

    .form-de-busca-veiculos{
        .titulo-do-bloco{ width: 100%; }
        .radio{
            display: inline-block;
            margin-top: 0;
            label{
                color: $preto;
                font-weight: 800;
                font-size: 14px;
            }
        }
        select{
            margin-bottom: 20px;
        }

        .enviar-contato{ margin-top: 30px; }
        .encontre-o-carro{
            padding-left: 0;
            margin-bottom: 30px;
        }
    }

    .card-carro{
        .thumb-foto{
            text-align: center;
            img{
                width: 100%;
                max-width: inherit;
            }
        }
    }

    .box-principais{
        .botao-default{
            padding-left: 30px;
            padding-right: 30px;
        }
    }


    .clique-menu{
        color: $branco;
        font-size: 28px;
        margin-top: 5px;
        display: inline-block;
        width: 100%;
        text-transform: uppercase;
        font-weight: 900;
    }


    .menu-mobile{
        display: none;
        margin-top: 10px;
        width: 100%;
        background: $branco;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        li{
            margin-bottom: 15px;
            a{
                text-transform: uppercase;
                font-weight: 900;
                color: $main-color;
            }
        }
    }

    .outros-botoes{
        background: $branco;
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 20px;
        display: none;
        .ms{
            li{
                a{
                    padding: 5px;
                    &.twitter{ background: $main-color; color: $branco;}
                    &.facebook{ background: $main-color; color: $branco;}
                    &.instagram{ background: $main-color; color: $branco;}
                    &.youtube{ background: $main-color; color: $branco;}
                }
            }
        }
    }


    header{
        .botao-default{
            &.ligamos-para-voce{ margin-top: 15px; }
        }

        .logo{
            margin-top: 0px;
            position: inherit;
            a{
                display: inline-block;
                text-align: center;
                img{
                    max-height: 50px;
                }
            }
        }

        .numeros{
            width: 100%;
            text-align: center;
            margin-left: 0;

            .numero-do-whatsapp {
                margin-left: 0;
                width: 100%;
                display: inline-block;
            }
        }

    }

    .box-de-atendimento{
        .click-atendimentos{
            margin-bottom: 20px;
            .botao-default{
                padding-left: 50px;
                padding-right: 50px;
            }
        }
    }

    .titulo-pagina{
        font-size: 18px;
    }

    .beneficios{
        padding-left: 20px;
        padding-right: 20px;
    }

    .tags-beneficios{ font-size: 14px; }

    .box-terceiro-menu{
        .nome-da-pagina{
            margin-left: 0;

            &.espaco-consorcio{
                margin-left: 0;
            }
        }
    }

    .of-servicos{
        .box-atendimento-oficina{
            padding-bottom: 70px;
            margin-bottom: 20px;
            height: auto;

            .card-de-oficina{
                margin-top: 15px;
            }
        }
    }

    .sobre-news{
        display: block;
        text-align: center;
        width: 100%;
        margin-left: 0;
        .pequeno-texto-news{
            width: 100%;
        }
    }
    .box-news{
        input{
            margin-bottom: 20px;
        }
    }


    .box-informacoes{
        text-align: center;
    }

    .titulo-marcas,
    .busca-avancada{ width: 60%; }

    .box-assinatura{
        .todos-os-direitos{
            text-align: center;
        }
    }

    .barra-de-pesquisa{
        .form-inline{
            .form-group{
                .input-group{
                    .input-group-addon{
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
            }

        }
    }

    .barra-de-pesquisa .form-inline .form-group .input-group .input-group-addon button{
        height: 30px;
        font-size: 11px;
    }

    .barra-de-pesquisa .form-inline .form-group .input-group input{
        height: 30px;
        font-size: 11px;
    }

    .numeros span{ font-size: 12px; }
    .numeros span.numero-do-whatsapp i{ font-size: 17px; }

    .box-cta-botao{
        width: 100%;
        position: fixed;
        bottom: 90px;
        z-index: 998;

        .botao-tenho-interesse-lead{
            text-transform: uppercase;
            font-weight: 900;
            font-size: 12px;
            color: $branco;
            background: $main-color;
            padding: 12px;
            padding-left: 15px;
            padding-right: 15px;
            @include radius(12px);
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        }
    }

    .car-right-box{
        position: relative;
        margin-bottom: 30px;
    }
    .box-interna-carro{ margin-top: 0px;}
    .box-interna-carro .fotos-do-carro{ margin-top: 0; }

    .ofertas-header-mobile{ font-size: 11px; }

    .maps-full{
        //padding-top: 0px;
    }
    .lojas-mapa{
        flex-direction: column;

        a{
            width: 100%;
        }
    }

    .menu-top .menu-top_content .menu-top-content_nav .menu-top-content-nav_items a.social-media{
        display: inline;
        font-size: 2.5rem;
        padding: 0px 2px;
    }

}


@media screen and (min-width: 540px) {
    .form-de-busca-veiculos{
        .radio{
            display: block;
            margin-top: 10px;
        }
    }

    .card-carro{
        .thumb-foto{
            text-align: center;
            img{
                width: 100%;
                max-width: inherit;
            }
        }

        .box-de-tag{
            img{
                &.corrigir-tamanho-um{ width: 24%; }
                &.corrigir-tamanho-dois{ width: 28%; }
            }
        }
    }

    header{
        .logo{

            a{

                img{
                    //max-width: 110px;
                    
                }
            }
        }
    }

    .numeros span{ font-size: 14px; }
    .numeros span.numero-do-whatsapp i{ font-size: 20px; }

    .ofertas-header-mobile{
        font-size: 12px
    }
}

@media (max-width: 768px) {

    .ass{
        text-align: center;
    }
    
    body{
        padding-bottom: 70px;
    }
    .logo{
        margin-bottom: 0px;
        a{
          max-height: 50px;
          padding-bottom:0px;
        }
    }
    .lojas-mapa{
        display: block;
    }

    .card-carro{
        padding-bottom: 0px;
        ul.caixa-specs{
            bottom: 42px;
        }
    }

    .full-map-info{

        .logo-footer{
            text-align: center;
            margin-bottom: 20px;
        }

    }

    .barra-de-pesquisa{
        .form-inline{
            .form-group{
                margin-bottom: 0px !important;
            }
        }
    }
}


@media screen and (min-width: 768px) {
    .menu-top{
        background-color: transparent;
    }
    .card-carro{

        .box-de-tag{
            img{
                &.corrigir-tamanho-um{ width: 44%; }
                &.corrigir-tamanho-dois{ width: 50%; }
            }
        }
    }

    .titulo-marcas,
    .busca-avancada{ width: 30%; }

    header{
        .logo{

            a{

                img{
                    //padding-top: 17%;
                    //max-width: 140px;
                    max-height: none;
                }
            }
        }
    }

    .barra-de-pesquisa{
        margin-top: 8px;
        .form-inline{
            .form-group{
                .input-group{
                    .input-group-addon{
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }

        }
    }

    .barra-de-pesquisa .form-inline .form-group .input-group input{
        height: 40px;
        font-size: 14px;
    }
}

@media screen and (min-width: 768px) {
    .padding-top{
        padding-top: 85px;
    }
    .oportunidades{
        .botao-default{
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            display: inline-block;
            font-size: 16px;
        }
    }

    header{

        .logo{
            a{
                img{
                    float:left;
                    //padding-top: 20%;
                    width: 100%;
                    //max-width: 182px;
                }
            }
        }
        .barra-menu-secundario{
            .form-inline{
                .form-group{
                    .input-group{
                        .input-group-addon{
                            .pesquisar-estoque{
                                padding-right: 0;
                                padding-top: 3px;
                            }
                            i{ font-size: 15px; }
                        }
                    }
                }
            }
        }

        .numeros{
            width: inherit;
            text-align: right;
            .numero-de-atendimento,
            .numero-do-whatsapp{
                display: inline-block;
                font-size: 10px;
                i{ font-size: 15px; }
            }
            .numero-do-whatsapp{
                margin-left: 40px;
                width: auto;
                display: inherit;
            }
        }

        .menu{
            li{
                a{
                    font-size: 10px;
                }
            }
        }

        .botao-default{
            &.ligamos-para-voce{
                margin-top: 0;
                font-size: 10px;
            }

        }

    }

    .beneficios{
        padding-left: 100px;
        padding-right: 100px;
    }

    .tags-beneficios{ font-size: 18px; }

    .box-terceiro-menu{
        .nome-da-pagina{
            margin-left: 210px;

            &.espaco-consorcio{
                margin-left: 380px;
            }
        }
    }

    .of-servicos{
        .box-atendimento-oficina{
            padding-bottom: 0;
            margin-bottom: 0;
            height: 260px;

            .card-de-oficina{
                margin-top: 0;
                .botao-default{
                    font-size: 12px;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }

    .box-informacoes{
        text-align: left;
    }

    .titulo-marcas,
    .busca-avancada{ width: 20%; }

    .box-assinatura{
        .todos-os-direitos{
            text-align: left;
        }
    }

    .box-cta-botao{
        display: none;
    }

    

    .box-interna-carro .fotos-do-carro{ margin-top: 20px; }
    .box-interna-carro{ margin-top: 20px;}

    .barra-de-pesquisa{
        margin-top: 0px;
        
    }

    .barra-menu-header .menu li a{
        font-size: 12px;
    }

}

@media screen and (min-width: 1200px) {

    .box-marcas{
        .marcas-seminovos{
            text-align: center;
            .item-marca-seminovos{
                margin-bottom: 15px;
            }
        }

        .marcas{
            text-align: center;
            .item-marcas{
                margin-bottom: 15px;
            }
        }
    }


    .form-de-busca-veiculos{
        .radio{
            display: block;
            margin-top: 0;
        }

        select{
            margin-bottom: 0;
        }

        .enviar-contato{ margin-top: 0; }
        .encontre-o-carro{
            padding-left: 30px;
            margin-bottom: 0;
        }
    }

    header{

        .numeros{
            text-align: right;
            .numero-de-atendimento,
            .numero-do-whatsapp{
                font-size: 14px;
                i{ font-size: 22px; }
            }
            .numero-do-whatsapp{
                margin-left: 40px;
            }
        }

        .menu{
            li{
                a{
                    font-size: 14px;
                }
            }
        }

        .botao-default{
            &.ligamos-para-voce{
                font-size: 12px;
            }

        }


        .logo{
            a{
                img{
                    float: left;
                    //max-width: 182px;
                }
            }
        }
    }

    .of-servicos{
        .box-atendimento-oficina{
            padding-bottom: 0;
            margin-bottom: 0;
            height: 260px;

            .card-de-oficina{
                margin-top: 0;
                .botao-default{
                    font-size: 16px;
                    padding-left: 60px;
                    padding-right: 60px;
                }
            }
        }
    }

    .box-de-atendimento{
        .click-atendimentos{
            margin-bottom: 0;
            .botao-default{
                padding-left: 100px;
                padding-right: 100px;
            }
        }
    }

    .sobre-news{
        display: inline-block;
        width: 45%;
        text-align: left;
        margin-left: 20px;

        .titulo-pagina{
            width: 100%;
            margin-bottom: 20px;
            margin-top: 0;
        }

    }
    .box-news{
        input{
            margin-bottom: 0;
        }
    }

    .barra-menu-header .menu li a{
        font-size: 14px;
    }

}

@media screen and (min-width: 1400px) {

}
