body{
	font:{
		family: $lato;
		size: 16px;
		weight: 400;
	};
	color: #595959;

	.form-control{
		border: solid 1px #f1f1f1 !important;
		padding: 11px 18px !important;
		font-size: 0.8rem !important;
		&::placeholder{
			color: #8D8D8D !important;
		}
	}
}

h1, h2, h3, h4, h5{margin: 0px; font-weight: inherit;}

ul{
	padding: 0;
	margin: 0;
	li{
		list-style: none;
	}
}

a{
	text-decoration: none;
	color: inherit;
	&:hover{
		text-decoration: none;
		color: inherit;
	}
}

// Gerais
