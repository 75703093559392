.main-color {
    color: $main-color;
}
.secondary-color {
    color: $secondary-color;
}

.container-titulo-pagina {
    background: #f1f1f1;
    padding: 50px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.titulo-pagina {
    text-transform: uppercase;
    font: {
        size: 30px;
        weight: 300;
    }
    color: $title-color;
    display: inline-block;
}

.box-slider-desktop {
    position: relative;
    .seta-esquerda,
    .seta-direita,
    .seta-direita-mobile,
    .seta-esquerda-mobile {
        z-index: 100;
        color: #fff;
        position: absolute;
        top: 45%;
        display: none;
        width: 50px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        @include transition(0.3s);
        cursor: pointer;
        font-size: 50px;
    }
    &:hover {
        .seta-esquerda,
        .seta-direita,
        .seta-direita-mobile,
        .seta-esquerda-mobile {
            display: block;
        }
    }
    .seta-direita,
    .seta-direita-mobile {
        right: 0;
    }
    .seta-esquerda,
    .seta-esquerda-mobile {
        left: 0;
    }
}

.slider-mobile {
    display: none;
}

.slider-desktop,
.slider-mobile {
    .item-slider {
        img {
            display: inline-block;
            max-width: 1920px;
            width: 100%;
        }
    }
}

@media (max-width: 700px) {
    .slider-desktop {
        display: none;
    }
    .slider-mobile {
        display: block;
    }
}

.box-busca-avancada {
    position: relative;
    height: 175px;

    .container {
        position: absolute;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        top: -58px;
        background: #fff;

        @media (max-width: 700px) {
            top: 0px;
        }
    }

    label {
        font-size: 14px;
        & > input[type="radio"] {
            margin-left: 20px;
        }
    }

    .noUi-horizontal {
        padding: 0 10px;
        margin-top: 7px;
        height: 8px;
    }
    .noUi-horizontal .noUi-handle {
        height: 18px;
        &:before,
        &:after {
            height: 9px;
            top: 4px;
        }
    }

    button[type="submit"] {
        padding: 11px 60px;
        font-size: 0.8rem;
        border-radius: 20px;
        background: $main-color;
        border: none;
        font-weight: bold;
    }

    .btn-mais-filtro {
        color: white;
        text-decoration: none;
    }
}

.titulo-marcas {
    display: block;
    text-transform: uppercase;
    background: #fff;
    margin: 0 auto;
    margin-top: -30px;
    width: 12%;
    font: {
        size: 12px;
    }
    color: #a1a1a1;
}

.marcas-seminovos {
    text-align: center;
    .item-marca-seminovos {
        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
        max-width: 80px;
        max-height: 50px;
        padding: 10px;
        display: inline-block;
        border: solid 1px #e5e5e5;
        @include radius(5px);
        @include transition(0.3s);
        img {
            display: inline-block;
        }
        &:hover {
            border: solid 1px #8a8a8a;
        }
    }
}

.marcas {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item-marcas {
        margin: 10px;
        padding: 10px;
        border: solid 1px #e5e5e5;
        border-radius: 5px;
        img {
            display: inline-block;
        }
        &:hover {
            border: solid 1px #8a8a8a;
        }
    }
}

.detalhes-marcas {
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
    padding-top: 20px;
    margin-top: 20px;
    text-align: center;
}

.busca-avancada {
    display: block;
    text-transform: uppercase;
    background: #f5f5f5;
    margin: 0 auto;
    margin-bottom: -45px;
    width: 12%;
    margin-top: 40px;
    @include transition(0.3s);
    font: {
        size: 12px;
    }
    color: #a1a1a1;
    &:hover {
        color: #b70a28;
    }
}

.box-principais {
    text-align: center;
    .botao-default {
        margin-top: 20px;
        margin-bottom: 50px;
        display: inline-block;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: $branco;
        text-align: center;
        background: $main-color;
        font: {
            size: 16px;
        }
        @include transition(0.3s);
        @include radius(2px);
        i {
            font-size: 12px;
        }

        &.todo-estoque {
            color: $btn-text;
        }
    }
}

.car-icon {
    display: inline-block;
    width: 20px;
    height: 21px;
    vertical-align: middle;
    &.combustivel {
        background: url(../img/icones/icons_hover.png);
        background-position: -46px 0px;
        padding-left: 23px;
    }
    &.portas {
        background: transparent url("../img/icones/07.png") scroll no-repeat left center;
    }
    &.cor {
        background: transparent url("../img/icones/06.png") scroll no-repeat left center;
    }
    &.cambio {
        background: transparent url("../img/icones/05.png") scroll no-repeat left center;
    }
    &.ano {
        background: url(../img/icones/icons_hover.png);
        background-position: 0 0px;
        padding-left: 23px;
    }
    &.quilometragem {
        background: url(../img/icones/icons_hover.png);
        background-position: -24px 0px;
        padding-left: 23px;
    }
    &.motor {
        background: transparent url("../img/icones/02.png") scroll no-repeat left center;
    }
    &.car {
        background: transparent url("../img/icones/01.png") scroll no-repeat left center;
    }
}

.card-carro {
    background-color: #fff;
    margin-bottom: 20px;
    position: relative;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    .car-icon {
        color: #8D8D8D;
        &.ano {
            background: url(../img/icones/icons_hover.png);
            background-position: 0 0px;
        }
        &.quilometragem {
            background: url(../img/icones/icons_hover.png);
            background-position: -24px 0px;
        }
        &.combustivel {
            background: url(../img/icones/icons_hover.png);
            background-position: -46px 0px;
        }
    }
    &:hover {
        .valor {
            color: $main-color !important;
        }
        .cifrao {
            color: $main-color !important;
        }
        .nome-do-carro {
            color: $main-color !important;
        }
        .versao {
            color: $main-color !important;
        }

        .car-icon {
            color: $main-color;
            &.ano {
                background: url(../img/icones/icons_hover.png);
                background-position: 0 24px;
            }
            &.quilometragem {
                background: url(../img/icones/icons_hover.png);
                background-position: -24px 24px;
            }
            &.combustivel {
                background: url(../img/icones/icons_hover.png);
                background-position: -46px 24px;
            }
        }
    }
    .tag-oferta {
        color: white;

        text-align: center;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 140px;
        height: 0;
        z-index: 1;
        font-size: 12px;
        font-weight: 600;
        border-top: 20px solid $main-color;
        border-right: 15px solid transparent;
        border-left: 15px solid $main-color;

        span {
            position: absolute;
            width: 115px;
            top: -22px;
            left: -10px;
        }
    }
    .botao-default {
        display: block;
        background: unset;
        text-transform: capitalize;
        color: $main-color;
        text-align: center;
        border-radius: 5px;
        border: solid 1px $main-color;
        font-size: 12px;
        font-weight: 700;
        width: fit-content;
        padding: 10px 10px !important;
        margin: 0;
        i {
            font-size: 12px;
        }

        &.ligamos-para-voce {
            background: #21a45c;
            &:hover {
                background: #26d775;
            }
        }
    }

    .container-nome {
        .marca-do-carro {
            display: inline-block;
            width: 100%;
        }

        .nome-do-carro {
            color: $main-color;
            width: 100%;
            display: flex;
            align-items: center;

            img {
                width: 26px;
            }
        }

        .versao,
        .tipo-do-carro {
            color: $main-color;
            height: 35px;
            text-transform: uppercase;
            margin: 0;
            display: flex;
            font: {
                size: 11px;
            }
        }

        ul.caixa-specs {
            display: flex;
            li {
                color: #999;
                font-size: 10px;
                padding: 5px 2px;

                &:first-child {
                    border-left: none;
                }
                &:last-child {
                    border-right: none;
                }
                span.car-icon {
                    width: auto;
                    line-height: 20px;
                }
            }
        }
    }
    .caixa-whatsapp {
        text-align: center;
        background-color: #5cb85c;
        color: white;
        padding: 10px;
        margin-top: 10px;
    }
}

.caixa-foto {
    position: relative;
    margin-bottom: 15px;
    display: block;
    width: 100%;
    border-bottom: 1px solid #ccc;
    img {
        width: 100%;
    }

    .icon-play {
        position: absolute;
        display: block;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        cursor: pointer;
        background-color: lighten($main-color, 10%);
        font-size: 30px;
        color: $branco;
        padding: 25px 25px 25px 30px;
        border-radius: 60px;
        opacity: 0.9;
        &:hover {
            background-color: lighten($main-color, 40%);
        }
    }

    .local-venda {
        background: #313131;
        color: $branco;
        font-size: 10px;
        padding: 2px 4px;
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0.5;
    }

    .botao-comparar {
        position: absolute;
        top: 5px;
        right: 5px;
        font-weight: 700;
        color: $branco;
        text-transform: uppercase;
        font-size: 9px;
        display: inline-block;
        @include radius(5px);
        @include transition(0.3s);
        padding: 3px 7px;
        background: $secondary-color;
        opacity: 0.5;
        &:hover {
            background: darken($secondary-color, 10%);
            opacity: 0.9;
        }
    }
}

.caixa-de-valor {
    background: #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .valor-do-carro {
        .cifrao {
            color: $main-color;
            font-size: 14px;
            font-weight: normal;
        }
        .valor {
            color: $main-color;
            font-size: 20px;
            font-weight: 900;
        }
    }
}

.car-right-box {
    position: absolute;
}

.box-news {
    padding-top: 70px;
    padding-bottom: 100px;
    background: #f7f7f7;

    input {
        height: 50px;
        @include radius(50px);
        width: 100%;
        border: solid 1px #cccccc;
        padding-left: 20px;
        padding-right: 20px;
    }

    .enviar-contato {
        height: 50px;
        line-height: 50px;
        color: #55401a;
        background: $main-color;
        @include transition(0.3s);
        display: inline-block;
        margin-bottom: 0px;
        margin-top: 0;
        cursor: pointer;
        width: 100%;
        border: none;
        font-size: 18px;
        @include radius(50px);
        &:hover {
            background-color: darken($main-color, 10%);
        }
    }
}

.icone-news {
    display: inline-block;
    vertical-align: top;
    i {
        color: #b70a28;
        font-size: 100px;
    }
}

.texto-news {
    margin-bottom: 65px;
}

.sobre-news {
    display: inline-block;
    width: 45%;
    text-align: left;
    margin-left: 20px;

    .titulo-pagina {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0;
        color: #b70a28;
    }

    .pequeno-texto-news {
        color: #615f6c;
        font-weight: 400;
        font-size: 14px;
        display: inline-block;
        width: 60%;
    }
}

.contato {
    text-align: center;
    margin-bottom: 40px;
    input {
        height: 40px;
        border: solid 1px #aaaaaa;
        @include radius(3px);
        margin-bottom: 20px;
    }

    textarea {
        margin-bottom: 20px;
    }

    .enviar-contato {
        display: inline-block;
        background: $main-color;
        border: none;
        text-transform: uppercase;
        color: $branco;
        cursor: pointer;
        @include transition(0.3s);
        @include radius(50px);
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 10px;
        padding-bottom: 10px;
        &:hover {
            background-color: darken($main-color, 10%);
        }
    }
}

.box-de-atendimento {
    margin-bottom: 50px;
    .click-atendimentos {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;

        .botao-default {
            margin-top: 20px;
            margin-bottom: 0;
            display: inline-block;
            background: #ae0926;
            padding-left: 100px;
            padding-right: 100px;
            padding-top: 10px;
            padding-bottom: 10px;
            color: $branco;
            text-align: center;
            font: {
                size: 16px;
            }
            @include transition(0.3s);
            @include radius(50px);
            i {
                font-size: 12px;
            }
            &:hover {
                background: $main-color;
            }
            &.ligamos-para-voce {
                background: #21a45c;
                &:hover {
                    background: #26d775;
                }
            }
            &.botao-azul {
                background: #2455ae;
                &:hover {
                    background: #3470df;
                }
            }
            &.btn-whatsapp {
                background: #5cb85c;
                &:hover {
                    color: #fff;
                    background-color: #449d44;
                }
            }
        }
    }
}

.lojas {
    text-align: center;
    .botao-default {
        margin-top: 20px;
        margin-bottom: 50px;
        display: inline-block;
        background: #1a1a1a;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: $branco;
        text-align: center;
        font: {
            size: 16px;
        }
        @include transition(0.3s);
        @include radius(50px);
        i {
            font-size: 12px;
        }
        &:hover {
            background: $main-color;
        }
    }
}

.card-loja {
    text-align: center;
    background: $branco;
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 12px;
    margin-bottom: 20px;
    img {
        width: 100%;
        max-width: 260px;
        display: inline-block;
        margin-bottom: 25px;
    }

    .nome-da-loja {
        display: block;
        font-weight: bold;
        font-size: 18px;
    }

    .botao-default {
        margin-top: 25px;
        margin-bottom: 15px;
        display: inline-block;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        background: $secondary-color;
        font-weight: 900;
        text-transform: uppercase;
        color: $branco;
        text-align: center;
        font: {
            size: 16px;
        }
        @include transition(0.3s);
        @include radius(2px);
        i {
            font-size: 12px;
        }
        &:hover {
            background: $main-color;
        }
    }
}

.banner-servicos {
    margin-top: 20px;
}

.servicos {
    margin-bottom: 60px;
    .card-loja {
        height: 580px;
        img {
            max-width: 360px;
            height: 100%;
            max-height: 170px;
        }
    }

    .texto-servico {
        text-align: center;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .botao-loja {
        margin-bottom: 20px;
    }

    .visite-o-site {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 10px;
        display: inline-block;
        color: #5b5b5b;
        text-decoration: underline;
        font-weight: 900;
    }
}

.box-quem-somos {
    .nome-do-local {
        text-align: center;
        display: inline-block;
        width: 100%;
    }

    .click-atendimentos {
        text-align: center;
        background: #eeeeee;
        padding-top: 50px;
        padding-bottom: 50px;

        .botao-default {
            margin-top: 20px;
            margin-bottom: 0;
            display: inline-block;
            background: #ae0926;
            padding-left: 100px;
            padding-right: 100px;
            padding-top: 10px;
            padding-bottom: 10px;
            color: $branco;
            text-align: center;
            font: {
                size: 16px;
            }
            @include transition(0.3s);
            @include radius(50px);
            i {
                font-size: 12px;
            }
            &:hover {
                background: $main-color;
            }
            &.ligamos-para-voce {
                background: #21a45c;
                &:hover {
                    background: #26d775;
                }
            }
            &.botao-azul {
                background: #2455ae;
                &:hover {
                    background: #3470df;
                }
            }
        }
    }

    .card-loja {
        text-align: center;
        background: $branco;
        border: 1px solid $secondary-color;
        color: $secondary-color;
        padding-top: 50px;
        padding-bottom: 50px;
        height: inherit;

        .texto-servico {
            margin-top: 0;
        }

        .botao-default {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.container-atendimento {
    background: #f1f1f1;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 15px 0px;

    .btn {
        font-weight: bold;
    }

    .btn-whatsapp {
        color: #fff;
    }
}

.galeria-de-quem-somos {
    .item-galeria {
        text-align: center;
        margin-bottom: 20px;

        img {
            width: 100%;
            max-width: 260px;
            display: inline-block;
        }
    }
}

.texto-sobre {
    /*margin-top: -220px;*/
    margin-bottom: 50px;
    .titulo-pagina {
        width: 100%;
        display: inline-block;
        text-align: center;
        font-size: 22px;
    }

    p {
        font-size: 1rem;
        color: #8D8D8D;
        display: inline-block;
        margin-bottom: 15px;
    }
}

.filtro {
    background: #f1f1f1;
    display: none;
}

.filtro[style*="display: block"] {
    display: flex !important;
}

.box-estoque {
    padding-top: 50px;

    .ordenar-por {
        span {
            display: inline-block;
            width: 100%;
            text-align: left;
            font-size: 11px;
            color: #1b1b1b;
        }
    }

    .ordenar {
        margin-top: 20px;
        margin-bottom: 20px;
        li {
            display: inline-block;
            border-right: solid 1px #aeaeae;
            padding-right: 10px;
            padding-left: 10px;
            a {
                @include transition(0.3s);
                font-size: 14px;
                color: #1b1b1b;
                &:hover {
                    color: $main-color;
                }
            }
        }
        li:first-child {
            padding-left: 0;
        }
        li:last-child {
            border-right: none;
        }
    }

    .mostrar-quantidade {
        margin-top: 15px;
        span {
            display: inline-block;
            font-size: 11px;
            color: #1b1b1b;
        }

        select {
            display: inline-block;
            width: auto;
            margin-left: 10px;
            @include radius(0);
            height: 30px;
            font-size: 11px;
        }
    }
}

.quantidade-de-paginas {
    text-align: right;
    border-top: solid 1px #c9c9c9;
    padding-top: 20px;
    padding-bottom: 20px;
    li {
        margin-left: 3px;
        margin-right: 3px;
        display: inline-block;
        background: #f2f2f2;
        @include radius(2px);
        @include transition(0.3s);
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        a {
            color: #9f9f9f;
            @include transition(0.3s);
        }
        &:hover {
            background: $main-color;
            a {
                color: $branco;
            }
        }

        &.pagina-atual {
            background: $main-color;
            a {
                color: $branco;
            }
        }
    }
}

.form_busca_fixed {
    position: fixed !important;
    top: 0px !important;
    width: 100% !important;
    max-width: unset !important;
    border-radius: 0px 0px 10px 10px !important;
    z-index: 99 !important;
}

.caixa-avancada {
    background: #f7f7f7;
    margin-bottom: 50px;

    .quantidade-encontrada {
        width: 100%;
        background: $main-color;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;

        .quantidade {
            font-size: 24px;
            font-weight: 900;
            color: $branco;
            display: inline-block;
            width: 100%;
        }

        span {
            text-transform: uppercase;
            color: $branco;
            font-weight: 300;
            font-size: 12px;
        }
    }

    .selecionados {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;

        span {
            display: inline-block;
            width: 100%;
            color: #1b1b1b;
            font-size: 14px;
            margin-bottom: 20px;
        }

        .item-selecionado {
            display: inline-block;
            margin-bottom: 20px;
            margin-right: 15px;
            .fechar {
                @include radius(50%);
                display: inline-block;
                width: 20px;
                height: 20px;
                color: $branco;
                font-weight: 900;
                background: $main-color;
                line-height: 20px;
                text-align: center;
                font-size: 14px;
                margin-right: 5px;
            }

            .nome-do-item {
                color: #1b1b1b;
                font-size: 12px;
                display: inline-block;
                width: inherit;
                margin-bottom: 0;
            }
        }
    }

    .tag {
        background: $preto;
        width: 100%;
        display: inline-block;
        color: $branco;
        text-transform: uppercase;
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
    }

    .busca {
        li {
            a {
                text-align: center;
                display: block;
                position: relative;
                height: 40px;
                width: 100%;
                line-height: 40px;
                color: #1b1b1b;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 400;
                @include transition(0.3s);
                &:hover {
                    color: $branco;
                    background: $main-color;
                }

                .fa-angle-right {
                    position: absolute;
                    right: 5%;
                    top: 14%;
                    font-size: 25px;
                }
                &.busca-estoque-ativa {
                    color: $branco;
                    background: $main-color;
                }
            }

            .sub-opcoes {
                max-height: 300px;
                overflow-y: auto;
                display: none;
                background-color: $main-color;
                padding: 10px;
                color: $branco;
                li {
                    a {
                        padding-left: 15px;
                        text-align: left;
                        color: $branco;
                    }
                    select {
                        color: #000;
                    }
                }
            }
        }
    }

    .form-estoque {
        padding: 0 10px;
        .form-group {
            margin-bottom: 0px;
            margin-top: 10px;

            .bg-main-color {
                background: $main-color;
                border-radius: 5px;
                padding: 5px 0px;
                margin-top: 10px;
                width: 100%;
            }

            .form-buscar {
                border: none;
                background: none;
                color: white;
            }
        }
    }

    .encontre-aqui {
        background: #1b1b1b;
        padding: 10px;

        .barra-de-pesquisa {
            margin: 0;
            height: 30px;
            .form-inline {
                .form-group {
                    .input-group {
                        input {
                            height: 30px;
                        }
                        .input-group-addon {
                            padding-right: 5px;

                            .botao-estoque-submit {
                                height: 30px;
                            }
                            i {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.box-interna-carro {
    
    margin-top: 50px;

    .marca-do-carro,
    .nome-do-carro {
        display: inline-block;
        &:hover {
            color: white;
        }
    }

    .marca-do-carro {
        vertical-align: top;
        padding-top: 5px;
        margin-right: 10px;
        img {
            display: inline-block;
        }
        &:hover {
            color: white;
        }
    }

    .nome-do-carro-interna {
        color: $main-color;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 300;
        display: inline-block;
        strong {
            font-size: 1.3rem;
            font-weight: 900;
        }
    }

    .fotos-do-carro {
        margin-top: 20px;
        position: relative;
        img {
            width: 100%;
            max-height: 580px;
        }
        .seta-esquerda,
        .seta-direita,
        .seta-direita-mobile,
        .seta-esquerda-mobile {
            z-index: 100;
            color: #fff;
            position: absolute;
            top: 45%;
            display: none;
            width: 50px;
            text-align: center;
            line-height: 50px;
            height: 50px;
            background: transparent;
            border: 0;
            margin: 0;
            padding: 0;
            @include transition(0.3s);
            cursor: pointer;
            font-size: 50px;
        }
        &:hover {
            .seta-esquerda,
            .seta-direita,
            .seta-direita-mobile,
            .seta-esquerda-mobile {
                display: block;
            }
        }
        .seta-direita,
        .seta-direita-mobile {
            right: 0;
        }
        .seta-esquerda,
        .seta-esquerda-mobile {
            left: 0;
        }
    }

    .galeria-thumb {
        background: #e6e6e6;
        padding-top: 10px;
        text-align: center;
        .item-thumbs {
            max-width: 100px;
            display: inline-block;
            margin-left: 5px;
            margin-right: 5px;
            margin-bottom: 10px;
            img {
                display: inline-block;
                cursor: pointer;
                width: 100%;
                max-width: 95px;
            }
        }
    }

    .gallery-medias {

        display: grid;
        grid-template-columns: 2fr 1fr 1fr;

        .main-element{
            grid-row: span 2;
        }

        img,
        iframe {
            width: 100%;
        }

        .overlay-last-image {
            position: absolute;
            background-color:rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;

            color: #F1F1F1;

            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.quadro-informacoes-do-carro {
    .item-informacao {
        height: 50px;
        line-height: 50px;
        padding-left: 12px;
        font-size: 14px;
        font-weight: 300;
        img {
            display: inline-block;
            margin-right: 10px;
        }

        &.cinza {
            background: #f7f7f7;
        }
    }
}

.caixa-caracteristica {
    margin-bottom: 50px;

    .caixa-titulo {
        display: inline-block;
        width: 100%;
        padding-left: 15px;
        height: 60px;
        color: $branco;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 25px;
        background: #313131;
        line-height: 60px;
    }

    .tag-principal {
        display: inline-block;
        width: 100%;
        color: $main-color;
        font-size: 0.8rem;
        text-transform: uppercase;
        font-weight: 900;
    }

    .tag {
        display: inline-block;
        width: 100%;
        padding-left: 20px;
        color: $preto;
        font-size: 16px;
        margin-bottom: 15px;
    }

    .info-caracteristica {
        display: inline-block;
        width: 100%;
        color: $preto;
        font-weight: 400;
        padding-left: 20px;
        font-size: 12px;
        margin-bottom: 30px;
        padding-right: 60px;
    }
}

.compartilhar {
    border-top: solid 1px #aaaaaa;
    padding-top: 10px;
    width: 95%;
    margin: 0 auto;
    margin-top: 30px;

    span {
        font-size: 12px;
    }
}

.compartilhar-btn {
    text-align: center;
    width: 100%;
    @include radius(5px);
    text-transform: uppercase;
    color: $branco;
    font-weight: 300;
    font-size: 14px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    margin-top: 20px;
    &:hover {
        color: $branco;
    }

    &.whatsapp {
        background: #65bc54;
    }
    &.facebook {
        background: #3c6ecb;
    }
    &.youtube {
        background: #dc3333;
    }
    &.twitter {
        background: #52d4f9;
    }
    &.instagram {
        background: #e87e21;
    }
}
.btn {
    &.btn-main {
        background-color: $main-color;
        &:hover {
            color: #fff;
            background-color: darken($main-color, 10%);
        }
    }
    &.btn-outline {
        border: solid 1px #f1f1f1;
        border-radius: 0.375rem;
        font-size: 0.8rem;
        color: $main-color;
        padding: 11px 18px;
    }
    &.btn-secondary {
        background-color: $main-color;
        &:hover {
            color: #fff;
            background-color: darken($secondary-color, 5%);
        }
    }
    &.btn-whatsapp {
        background-color: #5cb85c;
        &:hover {
            color: #fff;
            background-color: #449d44;
        }
    }
    &.btn-ligar {
        background-color: #5bc0de;
        &:hover {
            color: #fff;
            background-color: #31b0d5;
        }
    }
    &.btn-lojista {
        background-color: #337ab7;
        &:hover {
            color: #fff;
            background-color: #286090;
        }
    }
}

.texto-reservado {
    margin-top: 15px;
    p {
        font-size: 10px;
        color: #1b1b1b;
        margin-bottom: 0;
    }
}

.lista-de-caracteristicas {
    display: flex;
    li {
        display: flex;
        border: solid 1px #f1f1f1;
        border-radius: 5px;
        padding: 15px;
        text-align: center;
        span {
            font-size: 12px;
            color: $main-color;
        }
    }
    li:first-child {
        margin-left: 0;
    }
}

.checks {
}

.opcional-check {
    width: 100%;
    display: inline-block;
    color: $main-color;
    border: solid 1px #f1f1f1;
    padding: 10px;
    i {
        margin-right: 5px;
        font-size: 18px;
        color: #27ae60;
    }
}

.marca-da-loja {
    display: inline-block;
    padding: 30px;
    text-align: center;
    img {
        width: 100%;
        display: inline-block;
    }
    border: solid 1px #dedede;
    @include radius(10px);
    margin-left: 20px;
    margin-right: 20px;
    vertical-align: top;
}

.contato-da-loja {
    display: inline-block;
    span {
        display: inline-block;
        width: 100%;
        color: #717171;
        font-size: 12px;
    }
}

.lead-preco {
    border-top: solid 1px #fff;
    background: $preto;
    text-align: center;
}

.valor-carro {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;

    .cifrao {
        color: $main-color;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 14px;
    }

    .valor {
        color: $main-color;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 20px;
    }
}
.de {
    color: $main-color;
    font-size: 14px;

    .valor-antes {
        text-decoration: line-through;
    }
}

.tag-lead-interna {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 400;
    color: $branco;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-style: italic;
    padding: 0px 10px;
}

.box-inputs {
    padding-left: 10px;
    padding-right: 10px;
}

.lead-interna-carro {
    input {
        border: none;
        @include radius(0);
        margin-bottom: 12px;
        color: #1b1b1b;
    }

    textarea {
        border: none;
        @include radius(0);
        margin-bottom: 12px;
        height: 100px;
        color: #1b1b1b;
    }
}

.tag-lead-menor {
    color: $branco;
    font-size: 12px;
    font-weight: 800;
    display: inline-block;
}

.box-radios {
    .radio {
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
        label {
            color: #615f6c;
            font-size: 11px;
            font-weight: 300;
            color: $branco;
        }
    }
}

.btn-simulacao {
    background-color: $main-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: $branco;
    font-weight: 800;
    font-size: 16px;
    padding: 10px;
    text-align: center;
    display: inline-block;
    width: 95%;

    &:hover {
        color: $branco;
        background-color: darken($main-color, 10%);
    }
}
.enviar-lead-interna {
    margin-top: 15px;
    margin-bottom: 10px;
    display: inline-block;
    background: $main-color;
    color: $branco;
    width: 95%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    border: none;
    text-transform: uppercase;
    font-weight: 800;
    font: {
        size: 16px;
    }
    @include transition(0.3s);
    @include radius(1px);

    i {
        font-size: 12px;
    }

    &:hover {
        background-color: darken($main-color, 10%);
    }
}

.itens-do-painel {
    padding-left: 70px;
    padding-right: 70px;
    li {
        color: #000;
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 10px;
        list-style: square;
    }
}

.estoque-da-loja {
    background: #1b1b1b;
    padding-top: 20px;
    padding-bottom: 20px;

    .marca-do-estoque {
        width: 100%;
        background: $branco;
        @include radius(10px);
        height: 120px;
        text-align: center;
        line-height: 120px;
        img {
            width: 100%;
            display: inline-block;
            max-width: 150px;
        }
    }

    span {
        display: block;
        width: 100%;
        color: $branco;
        font-size: 12px;

        &.nome-do-estoque {
            text-transform: uppercase;
            font-size: 16px;
        }
    }

    .endereco-da-loja-estoque {
        margin-top: 16px;
    }

    p {
        color: $branco;
        font-size: 12px;
        margin-top: 20px;
    }

    .veja-mais {
        color: $branco;
        text-decoration: underline;
        font-size: 11px;
    }

    .ms {
        margin-top: 20px;
    }
}

.banner-estoque-loja {
    img {
        display: inline-block;
        width: 100%;
        max-width: 525px;
    }
}

.numero-atendimento {
    margin-top: 20px;
    .icone-atendimento {
        display: inline-block;
        margin-right: 10px;
        color: #fff12c;
        i {
            font-size: 20px;
        }
        .fa-whatsapp {
            color: #3bec7e;
        }
    }

    .numero-do-atendimento {
        display: inline-block;
        text-align: center;
        color: $branco;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 12px;
    }
}

.modal-header {
    background: $main-color;
    color: $branco;
    text-transform: uppercase;
    font-weight: 900;
}

.modal-body {
    .form-ajax {
        input {
            @include radius(0);
            margin-bottom: 15px;
            margin-top: 5px;
        }
    }
    &.no-padding {
        padding: 0px;
    }
}

.g-recaptcha,
.submit-action {
    border: none;
    width: 100%;
    display: inline-block;
    background: $main-color;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    color: $branco;
    text-align: center;
    font: {
        size: 16px;
    }
    @include transition(0.3s);
    @include radius(50px !important);
    i {
        font-size: 12px;
    }
    &:hover {
        background: $main-color;
    }
}

.box-radios {
    background-color: rgba(106, 106, 106, 1);
}

#modal-financiamento {
    select {
        height: 36px;
        margin-top: 5px;
        border-radius: 0;
    }
}

#modal-exit {
    .modal-content {
        width: 500px;
        height: 500px;
    }
    .modal-body {
        width: 100%;
        height: 100%;

        .descricao {
            p {
                color: white;
                margin-top: 25px;
                font-size: 15px;
                font-style: italic;
                padding-right: 43px;
                line-height: 19px;
            }
            button {
                background-color: white;
                color: #005aab;
                font-size: 14px;
                padding: 6px 14px;
                margin-top: 10px;
                font-style: italic;
                font-weight: 800;
            }
        }

        #form-popup {
            padding: 0px 18px 0px 39px;
            margin-top: 30px;

            p {
                color: #005aab;
                text-align: center;
                font-size: 12px;
                margin-bottom: 5px;
            }

            .bg-amarelo {
                padding: 6px;
                background-color: #f1ce00;
                border-radius: 5px;
            }
            .borda {
                border: solid 1px #005aab;
                border-radius: 5px;
                padding: 12px;
            }
            .form-group {
                margin-bottom: 0px !important;

                input {
                    margin-bottom: 0px !important;
                    text-align: center;
                    border-radius: 10px;
                    font-size: 12px;
                }
                .submit-action {
                    background: rgb(228, 55, 51);
                    background: linear-gradient(90deg, rgba(228, 55, 51, 1) 0%, rgba(155, 17, 15, 1) 100%);
                    border-radius: 5px !important;
                    font-weight: 600;
                }
            }
        }
    }
}

.diferenciais {
    position: relative;
    .seta-esquerda,
    .seta-direita {
        z-index: 100;
        color: black;
        position: absolute;
        top: 45%;

        width: 50px;
        text-align: center;
        line-height: 50px;
        height: 50px;
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        @include transition(0.3s);
        cursor: pointer;
        font-size: 50px;
    }

    .seta-direita,
    .seta-direita-mobile {
        right: -35px;
    }
    .seta-esquerda,
    .seta-esquerda-mobile {
        left: -35px;
    }

    padding-top: 50px;
    > .container {
        padding: 0 50px;
    }
    .card-diferencial {
        text-align: center;
        padding: 10px 40px;

        img {
            display: inline;
        }
    }
    p {
        color: $secondary-color;
    }
    p.descricao {
        text-transform: uppercase;
    }
    .diferencial-titulo {
        //border: 1px solid $secondary-color;
        max-width: 90%;
        margin: 5px auto;
        font-weight: bold;
        font-size: 20px;
    }
    h2 {
        color: $main-color;
        margin-bottom: 15px;
        span {
            color: $secondary-color;
        }
    }
}

.block-overflow {
    overflow: hidden;
}

.no-btn {
    background: 0 0;
    border: none;
}

.box-login {
    .card-login {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;

        &-body {
            flex: 1 1 auto;
            padding: 1.25rem;
        }
    }

    .login-info {
        @media (max-width: 767px) {
            margin-top: 15px;
            text-align: center;
        }
    }
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}

.mfp-iframe-scaler {
    .btn-medium {
        display: inline-block;
        width: 160px;
        height: 35px;
        padding: 0;
        line-height: 35px;

        font-size: 13px;
        font-weight: 600;
        border: none;
        border-radius: 4px;
        color: $cta-text-color;
        text-align: center;
        text-transform: uppercase;
    }

    .btn-full {
        width: 100%;
    }
    .btn-main-color {
        background-color: $main-color;

        &:hover {
            background-color: $main-color--lighter;
        }
    }
    a {
        position: absolute;
    }
}

/*------------------------------------*
 * #BREADCRUMB */
/*------------------------------------ */

.breadcrumb {
    margin-bottom: 0px;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
}
.breadcrumb-list {
    list-style: none;
    padding: 15px 0;
    border-bottom: solid 1px #F1F1F1;

    .divider {
        color: $main-color;
        font-size: 14px;
    }

    li {
        display: inline;

        a,
        a:link,
        a:visited {
            color: $main-color;
            font-size: 14px;
        }
        a:hover {
            color: black;
        }
    }
}

.container-modal-telefones {
    padding: 10px;
    border: solid 1px #ccc;
    border-radius: 15px;
    margin-bottom: 10px;

    h4 {
        text-align: left;
        font-weight: bold;
    }
}

.container-modal-telefones-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    span {
        font-size: 1.35rem !important;
        font-weight: 300;
    }

    .btn-default {
        border-width: 2px !important;
        border-radius: 10px;
        font-weight: bold;
        font-size: 1.8rem !important;
    }
}

/*------------------------------------*
 * #DEPOIMENTOS */
/*------------------------------------ */

.box-depoimentos {
    background: #f1f1f1;

    .depoimento {
        background: #fff;
        border-radius: 10px;

        .texto {
            color: #8d8d8d;
        }

        .foto {
            width: 45px;
            height: 45px;
            border-radius: 50%;
            overflow: hidden;
        }

        .nome {
            font-weight: bold;
        }
    }
}
